

    import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
    import { convertMdiBracketToIcon } from '@/mdi';
    import mapboxgl from 'mapbox-gl';
    import '/node_modules/mapbox-gl/dist/mapbox-gl.css';
    mapboxgl.accessToken = 'pk.eyJ1IjoiZXZlcnQtZGllcGVyaW5rIiwiYSI6ImNrb2lkc2YzNDAzYmcyb3B2ZHV2d3N6cXIifQ.9CIPydNc_qxZ0rDWfqAy5w';

    @Component
    export default class Map extends Vue {
        @Prop({ default: null }) public model: any;
        @Prop({ default: null }) public scenario: any;
        @Prop({ default: null }) public location: any;
        @Prop({ default: null }) public locations: any;
        @Prop({ default: null }) public scenarioLocations: any;
        @Prop({ default: 15 }) public zoom: any;
        @Prop({ default: false }) public showAreaSetID: any;
        @Prop({ default: null }) public areaSetsVisible: any;
        @Prop({ default: null }) public locationIsoLines: any;
        @Prop({ default: null }) public isoLineSet: any;
        @Prop({ default: null }) public isoLineMinutes: any;
        @Prop({ default: null }) public locationCatchmentArea: any;
        @Prop({ default: null }) public catchmentArea: any;
        @Prop({ default: null }) public area: any;
        @Prop({ default: null }) public refresh: any;
        @Prop({ default: '' }) public filter: any;
        @Prop({ default: '' }) public searchResults: any;
        @Prop({ default: null }) public pois: any;
        @Prop({ default: null }) public heatMapPoints: any;
        @Prop({ default: null }) public scenarioLocationsVisible: any;
        @Prop({ default: null }) public onlinePois: any;
        @Prop({ type: Number, default: 0 }) public leftPadding!: number;
        @Prop({ type: Number, default: 0 }) public rightPadding!: number;
        @Prop({ type: Boolean, default: false }) public report!: boolean;
        @Prop({ type: Number, default: 1 }) public mapProvider!: number;

        
        private mapProviders = [
            { id: 1, name: 'MapBox (OpenStreetMaps)' },
            { id: 2, name: 'Google Maps' },
            { id: 3, name: 'HERE Maps' }
        ];
        private map: any;
        private modelIsChanged: boolean = true;

        @Watch('mapProvider')
        private setMapProvider() {
            const _self = (this as any);
            // Remove all existing layers if they exist
            if (_self.map.getLayer('google-layer')) _self.map.removeLayer('google-layer');
            if (_self.map.getSource('google-tiles')) _self.map.removeSource('google-tiles');

            if (_self.map.getLayer('here-layer')) _self.map.removeLayer('here-layer');
            if (_self.map.getSource('here-tiles')) _self.map.removeSource('here-tiles');

            // Default Mapbox Layer (1)
            if (_self.mapProvider === 1) {
                
                // Add DEM (Digital Elevation Model) source for terrain
                _self.map.addSource('mapbox-dem', {
                    type: 'raster-dem',
                    url: 'mapbox://mapbox.terrain-rgb',
                    tileSize: 512,
                    maxzoom: 14
                });

                // Add 3D Terrain
                _self.map.setTerrain({ source: 'mapbox-dem', exaggeration: 1.5 });


                // Add 3D Buildings
                _self.map.addLayer({
                    id: '3d-buildings',
                    source: 'composite',
                    'source-layer': 'building',
                    filter: ['==', 'extrude', 'true'],
                    type: 'fill-extrusion',
                    minzoom: 12,
                    paint: {
                        'fill-extrusion-color': '#aaa',
                        'fill-extrusion-height': ['get', 'height'],
                        'fill-extrusion-base': ['get', 'min_height'],
                        'fill-extrusion-opacity': 0.6
                    }
                });
            }

            // Google Maps Layer (2)
            if (_self.mapProvider === 2) {
                _self.map.addSource('google-tiles', {
                    type: 'raster',
                    tiles: [
                        `https://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}&key=AIzaSyCgUvP8k1S9aCis6D9GxDWmOawcImn_Sv4`
                    ],
                    tileSize: 256
                });

                _self.map.addLayer({
                    id: 'google-layer',
                    type: 'raster',
                    source: 'google-tiles',
                    paint: {}
                });
            }

            // HERE Maps Layer (3)
            if (_self.mapProvider === 3) {
                _self.map.addSource('here-tiles', {
                    type: 'raster',
                    tiles: [
                        'https://1.base.maps.ls.hereapi.com/maptile/2.1/maptile/newest/normal.day/{z}/{x}/{y}/256/png8?apiKey=qG72dx8eH8kxNI9mjPfCQLK6-eQeI3aU4YS2v2j7IOs'
                    ],
                    tileSize: 256
                });

                _self.map.addLayer({
                    id: 'here-layer',
                    type: 'raster',
                    source: 'here-tiles',
                    paint: {}
                });
            }

            _self.reorderLayers();
        }
        private reorderLayers() {
            const _self = this;
            if (_self.map.getLayer('locationsMarker-layer')) _self.map.moveLayer('locationsMarker-layer');
            if (_self.map.getLayer('catchmentArea-border')) _self.map.moveLayer('catchmentArea-border', 'locationsMarker-layer');
            if (_self.map.getLayer('catchmentAreaImpact-border')) _self.map.moveLayer('catchmentAreaImpact-border', 'locationsMarker-layer');
        }

        private mounted() {
            const _self = (this as any);
            _self.map = new mapboxgl.Map({
                container: document.getElementById('googleMap' + this._uid),
                style: "mapbox://styles/mapbox/streets-v12", // Replace with your preferred map style
                center: [-71.224518, 42.213995],
                zoom: 9,
                showCompass: true,
                showZoom: true,
                attributionControl: false,


            });
            _self.map.on('click', 'locationsMarker-layer', function (e) {
                _self.$emit('locationLeftClick', { x: e.point.x, y: e.point.y, latitude: e.lngLat.lat, longitude: e.lngLat.lng, location: JSON.parse(e.features[0].properties.location) });
            });
            _self.map.on('contextmenu', 'locationsMarker-layer', function (e) {
                _self.$emit('locationRightClick', { x: e.point.x, y: e.point.y, latitude: e.lngLat.lat, longitude: e.lngLat.lng, location: JSON.parse(e.features[0].properties.location) });
            });

            _self.map.loadImage(require('../../assets/blocked-pattern.png'), function (error, image) {
                if (error) throw error;

                if (!_self.map.hasImage('blocked-pattern')) {
                    _self.map.addImage('blocked-pattern', image, { pixelRatio: 5 });
                }
            });

            _self.map.addControl(new mapboxgl.NavigationControl(), 'top-left');
            _self.map.addControl(new ToggleTiltControl(), 'top-left');


            _self.map.on('load', () => {
                

                
                _self.setMapProvider();

            });
        }

        @Watch('model')
        private onModelChanged() {
            console.log(5);
            this.modelIsChanged = true;
        }

        @Watch('locations')
        private async onLocationsChanged(value: any) {
            const _self = (this as any);
            const bounds = new mapboxgl.LngLatBounds();
            const boundsChanged = new mapboxgl.LngLatBounds();
            value.filter(function (location: any) { return location.visibility != 2 }).map(function (location: any) {
                if (location.changeType > 0) boundsChanged.extend([location.lng, location.lat]);
                bounds.extend([location.lng, location.lat]);
            });
            if (!this.report) {
                if (this.modelIsChanged == true && this.locations != null && this.locations.length > 0) {
                    this.modelIsChanged = false;
                    if (!bounds.isEmpty()) {
                        this.map.fitBounds(bounds, {
                            padding: 30,
                            maxZoom: 12,
                            duration: 1000, // Smooth animation duration in milliseconds
                        });
                    }
                }
                else if (!boundsChanged.isEmpty()) {
                    if (!bounds.isEmpty()) {
                        this.map.fitBounds(boundsChanged, {
                            padding: 30,
                            maxZoom: 12,
                            duration: 1000, // Smooth animation duration in milliseconds
                        });
                    }
                }
            }



            const geojson = {
                type: 'FeatureCollection',
                features: [
                ],
            };

            const uniqueUrls = new Set<string>();
            value.filter(function (location: any) {
                const normalUrl = _self.getLocationProperties(location, false).url;
                const selectedUrl = _self.getLocationProperties(location, true).url;

                uniqueUrls.add(normalUrl);
                uniqueUrls.add(selectedUrl);
            });
            const uniqueIcons = Array.from(uniqueUrls);


            const loadImagePromises = [...uniqueIcons].map((iconUrl) => {
                if (!_self.map.hasImage(iconUrl)) {
                    return new Promise<void>((resolve, reject) => {
                        _self.map.loadImage(iconUrl, (error, image) => {
                            if (error) {
                                console.error(`Error loading image: ${iconUrl}`, error);
                                reject(error);
                                return;
                            }
                            if (image) {
                                _self.map.addImage(iconUrl, image);
                                resolve();
                            } else {
                                reject(new Error('Image not found'));
                            }
                        });
                    });
                } else {
                    // If the image already exists, resolve immediately
                    return Promise.resolve();
                }
            });



            value.filter(function (location: any) { return location.visibility != 2 }).map(function (location: any) {
                geojson.features.push({
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: [location.lng, location.lat],
                    },
                    properties: _self.getLocationProperties(location, location?.id == _self.location?.id)
                })
            });
            if (_self.map.getLayer('locationsMarker-layer')) {
                _self.map.removeLayer('locationsMarker-layer');
            }
            if (_self.map.getSource('markers')) {
                _self.map.removeSource('markers');
            }


            _self.map.addSource('markers', {
                type: 'geojson',
                data: geojson,
            });


            await Promise.all(loadImagePromises);

            _self.map.addLayer({
                id: 'locationsMarker-layer',
                type: 'symbol',
                source: 'markers',
                layout: {
                    'icon-image': ['get', 'url'], // Dynamically assign icon
                    'icon-size': ['/', ['get', 'size'], 195],
                    'icon-allow-overlap': true,
                    'icon-anchor': 'top-left',
                    'icon-offset': [-97.5, -287],
                    'symbol-sort-key': ['get', 'zIndex'],

                },
            });



        }

        @Watch('location')
        private onLocationChanged(newLocation: any, oldLocation: any) {
            const _self = this as any;

            if (_self.map.getSource('markers')) {
                const source = _self.map.getSource('markers') as GeoJSONSource;
                const data = source._data as any; // Get the GeoJSON data

                const updateIconForLocation = (locationId: number | null) => {
                    const feature = data.features.find((f: any) => f.properties.location.id === locationId);
                    if (feature) {
                        const isSelected = locationId === newLocation?.id;
                        const iconProps = _self.getLocationProperties(feature.properties.location, isSelected);

                        feature.properties = { ...feature.properties, ...iconProps };
                    }
                };

                // Update icons for old and new selected locations
                if (oldLocation) updateIconForLocation(oldLocation.id);
                if (newLocation) updateIconForLocation(newLocation.id);

                // Refresh the source with the updated data
                source.setData(data);
            }
        }


        private getLocationProperties(location: any, selected: boolean = false, isScenario: boolean = false) {
            let overlay1 = '';
            if (selected) overlay1 = '/Selected';
            let overlay2 = '';
            if (location.changeType == 3) overlay2 = '/Edit';
            if (location.changeType == 2) overlay2 = '/Closing';
            if (location.changeType == 1) overlay2 = '/Opening';
            let round = '';
            if (location.type == 1 || location.type == 4 || location.type == 5) {
                if (isScenario) {
                    overlay1 = '/ScenarioRound';
                    round = 'round';
                }
                else {
                    round = 'round';
                }
            }
            else {
                if (isScenario) overlay1 = '/Scenario';
            }

            const zIndex = selected ? 21 : 20; // Higher zIndex for selected locations
            const icon = {
                url: '/api/models/location-groups/' + (location.locationGroupId === null ? -1 : location.locationGroupId) + '/logo/' + round + overlay1 + overlay2 + '?r=' + this.model.modified,
                size: 40,
                offset: -287,
                zIndex: zIndex,

            };
            if (location.type == 1) {
                icon.size = 20;
                icon.offset = 0;
                icon.zIndex = 17;
            } else if (location.type == 4) {
                icon.size = 30;
                icon.offset = 0;
                icon.zIndex = 18;
            } else if (location.type == 5) {
                icon.size = 40;
                icon.offset = 0;
                icon.zIndex = 19;
            }

            icon['location'] = location;
            return icon
        }
        private removeOpacityFromColor(color) {
            // Check if the color is an 8-digit hex (with opacity)
            if (/^#([0-9a-fA-F]{8})$/.test(color)) {
                return color.slice(0, 7); // Remove the last two characters (opacity)
            }

            // If it's already a 6-digit hex or invalid, return as-is
            return color;
        }

        @Watch('catchmentArea')
        @Watch('locationCatchmentArea')
        private onLocationCatchmentAreaChanged() {
            const _self = this;
            let maximumFractionDiff = 0;
            if (this.locationCatchmentAreaFeatures != null) {
                this.locationCatchmentAreaFeatures.forEach(function (feature: any) {
                    _self.map.data.remove(feature);
                });
            }
            if (this.catchmentArea == null || this.locationCatchmentArea == null || _self.locationCatchmentArea.featureCollection == null) {
                this.locationCatchmentAreaFeatures = null;
            } else {
                const maximumDelta = this.locationCatchmentArea.featureCollection.features.reduce((maxDelta: number, feature: any) => { return Math.max(maxDelta, Math.abs(feature.properties.fraction)); }, 0);
                const locationCatchmentAreaFeatures: any[] = [];
                const locationCatchmentAreaImpactFeatures: any[] = [];
                const total = this.locationCatchmentArea.featureCollection.features.reduce((accumulator: number, feature: any) => accumulator + feature.properties.value, 0);
                this.locationCatchmentArea.featureCollection.features.forEach(function (feature: any) {
                    if (feature.properties.valueSharePrevious <= _self.catchmentArea.coverage) {
                        let fractionDiff = 1 / (maximumDelta / Math.abs(feature.properties.fraction))
                        if (fractionDiff > 1) fractionDiff = 1.0;
                        if (fractionDiff > maximumFractionDiff) maximumFractionDiff = fractionDiff;
                        if (feature.properties.catchmentAreaZoneColor != null) {
                            feature.properties.color = feature.properties.catchmentAreaZoneColor;
                        }
                        else {
                            feature.properties.color = _self.pickHex([0, 96, 127], [75, 225, 255], fractionDiff);
                        }
                        let deltaString: string = '';
                        feature.properties['valueDelta'] = 0;

                        if (_self.location.changeType == 0 && _self.scenario.parentScenarioId != null && !_self.locationCatchmentArea.sameAsParent && feature.properties.value != feature.properties.valueDefault) {
                            let impact = feature.properties.value - feature.properties.valueDefault;
                            if (impact != 0) {
                                let impactString: any = impact;
                                if (_self.model.outputType == 1 || _self.model.outputType == 3) impactString = _self.formatNumberAsCurrency(impact, _self.model.currency);
                                deltaString = '<br/><div class="text-body-1">' + _self.$t('message.impact') + ' (' + feature.properties['areaCode'] + ') : ' + impactString + '</div>';
                                feature.properties['valueDelta'] = impact;
                                feature.properties['impactPercentage'] = 1 / (total / Math.max(Math.abs(impact), 1));
                                locationCatchmentAreaImpactFeatures.push(feature);
                                if (feature.properties.catchmentAreaZoneColor != null) {
                                    //NOI
                                    //if (impact < 0) feature.properties.color = '#ff5555';
                                    //if (impact > 0) feature.properties.color = '#55ff55';
                                }
                                else {
                                    //Gravitatie
                                    //const belowThreshold = (1 / (total / Math.max(Math.abs(impact), 1)) < 0.00025);
                                    //if (impact < 0 && !belowThreshold) feature.properties.color = _self.pickHex([255, 85, 85], [255, 200, 200], fractionDiff);
                                    //if (impact > 0 && !belowThreshold) feature.properties.color = _self.pickHex([85, 255, 85], [200, 255, 200], fractionDiff);
                                }
                            }
                        }
                        if (feature.properties.locationPropertyId == null) {
                            //Gravitatie
                            feature.properties['tooltip'] = '<h3>' + feature.properties['areaCode'] + '</h3><br/><div class="text-body-1">' + _self.$t('message.fraction') + ' : ' + Math.round(feature.properties['fraction'] * 100) / 100 + '</div>' + (deltaString != '' ? deltaString : '');
                        }
                        else {
                            let title: string = '';
                            let nonShared = '';
                            let shared = '';
                            let total = 0;
                            _self.locationCatchmentArea.locationProperties.forEach(function (locationProperty: any) {
                                if (feature.properties.locationPropertyId == locationProperty.id) {
                                    if (title == '') title = '<h3>' + convertMdiBracketToIcon(locationProperty.title) + '</h3>';
                                    if (locationProperty.fraction == 1) nonShared += '<div class="text-body-1">' + _self.$t('message.nonShared') + ' : ' + Math.round(locationProperty.output) + '</div>';
                                    if (locationProperty.fraction < 1 && locationProperty.fraction > 0) shared += '<div class="text-body-1">' + _self.$t('message.sharedWith') + ' ' + ((1 / locationProperty.fraction) - 1) + ' ' + _self.$t('message.otherLocations') + ' : ' + Math.round(locationProperty.output) + ' (' + locationProperty.originalOutput + ')</div>';
                                    total += locationProperty.output;
                                }
                            });
                            feature.properties['tooltip'] = title + '<br/>' + '<div class="text-body-1">' + _self.$t('message.total') + ' : ' + Math.round(total) + '</div>' + (nonShared != '' ? nonShared : '') + (shared != '' ? shared : '') + (deltaString != '' ? deltaString : '');
                        }

                        feature.properties.color = _self.removeOpacityFromColor(feature.properties.color);

                        //Add to collection
                        locationCatchmentAreaFeatures.push(feature);

                    }
                });

                if (_self.map.getLayer('catchmentArea-border')) {
                    _self.map.removeLayer('catchmentArea-border');
                }
                if (_self.map.getLayer('catchmentAreaImpact-border')) {
                    _self.map.removeLayer('catchmentAreaImpact-border');
                }
                if (_self.map.getSource('catchmentArea')) {
                    _self.map.removeSource('catchmentArea');
                }
                if (_self.map.getSource('catchmentAreaImpact')) {
                    _self.map.removeSource('catchmentAreaImpact');
                }


                _self.map.addSource('catchmentArea', {
                    type: 'geojson',
                    data: { "type": "FeatureCollection", "features": locationCatchmentAreaFeatures },
                });
                _self.map.addSource('catchmentAreaImpact', {
                    type: 'geojson',
                    data: { "type": "FeatureCollection", "features": locationCatchmentAreaImpactFeatures },
                });



                _self.map.addLayer({
                    id: 'catchmentArea-border',
                    type: 'fill',
                    source: 'catchmentArea',
                    layout: {},
                    paint: {
                        'fill-color': ['get', 'color'],       // Polygon fill color
                        'fill-opacity': 0.8,
                        'fill-outline-color': '#000',

                    },
                });

                _self.map.addLayer({
                    id: 'catchmentAreaImpact-border',
                    type: 'fill',
                    source: 'catchmentAreaImpact',
                    layout: {},
                    paint: {
                        'fill-opacity': 0.6,
                        'fill-pattern': 'blocked-pattern',
                    },
                });

                this.reorderLayers();



            }
            this.createLegend(maximumFractionDiff);
        }

        
        private createLegend(maximumFractionDiff: number) {
            this.legend = [];
            const _self = this;
            if (_self.catchmentArea != null) {

                if (this.model.catchmentAreaZones.length > 0) {
                    this.model.catchmentAreaZones.forEach(function (catchmentAreaZone: any) {
                        _self.model.catchmentAreas.forEach(function (catchmentArea: any) {
                            if (catchmentArea.name == catchmentAreaZone.catchmentAreaName && catchmentArea.id == _self.catchmentArea.catchmentAreaId) {
                                _self.legend.push(catchmentAreaZone);
                            }
                        });
                    })
                } else {
                    _self.legend.push({ title: '100%', color: _self.pickHex([0, 96, 127], [75, 225, 255], maximumFractionDiff) });
                    _self.legend.push({ title: '80%', color: _self.pickHex([0, 96, 127], [75, 225, 255], maximumFractionDiff * 0.8) });
                    _self.legend.push({ title: '60%', color: _self.pickHex([0, 96, 127], [75, 225, 255], maximumFractionDiff * 0.6) });
                    _self.legend.push({ title: '40%', color: _self.pickHex([0, 96, 127], [75, 225, 255], maximumFractionDiff * 0.4) });
                    _self.legend.push({ title: '20%', color: _self.pickHex([0, 96, 127], [75, 225, 255], maximumFractionDiff * 0.2) });
                }

            }
            this.$emit('legendChanged', this.legend);
        }



    }
    class ToggleTiltControl {
        private isTiltMode = false; // Default is normal mode (no tilt)

        onAdd(map) {
            this._map = map;
            this._container = document.createElement('div');
            this._container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';

            const toggleButton = document.createElement('button');
            toggleButton.innerHTML = '<i class="mdi mdi-axis-z-rotate-clockwise"></i>'; // MDI icon
            toggleButton.title = 'Toggle 3D Tilt Mode';
            toggleButton.style.cursor = 'pointer';

            // Toggle tilt mode on click
            toggleButton.addEventListener('click', () => {
                this.isTiltMode = !this.isTiltMode;

                if (this.isTiltMode) {
                    // Enable Tilt Mode (Fixed Tilt at 60°)
                    this._map.easeTo({ pitch: 60, bearing: -20, duration: 1000 });
                    toggleButton.style.backgroundColor = '#007bff'; // Highlight button
                    toggleButton.style.color = 'white';
                } else {
                    // Reset to Normal Mode (Flat at 0°)
                    this._map.easeTo({ pitch: 0, bearing: 0, duration: 1000 });
                    toggleButton.style.backgroundColor = ''; // Reset button style
                    toggleButton.style.color = 'black';
                }
            });

            this._container.appendChild(toggleButton);
            return this._container;
        }

        onRemove() {
            this._container.parentNode.removeChild(this._container);
            this._map = undefined;
        }
    }



