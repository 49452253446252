
    import { Component, Vue,Watch } from 'vue-property-decorator';
    import { Action, Getter, Mutation } from 'vuex-class';
    import FloatingMenu from '@/components/main/FloatingMenu.vue';
    import EditModelParameters from '@/components/main/EditModelParameters.vue';
    import LocationInformation from '@/components/main/LocationInformation.vue';
    import EditDrivers from '@/components/main/EditDrivers.vue';
    import LocationScores from '@/components/main/LocationScores.vue';
    import Dialog from '@/components/main/Dialog.vue';
    import GoogleMap from '@/components/main/GoogleMap.vue';
    import Map from '@/components/main/Map.vue';
    import ModelOutput from '@/components/main/ModelOutput.vue';
    import ScenarioProcessingLog from '@/components/main/ScenarioProcessingLog.vue';
    import CatchmentAreaInformation from '@/components/main/CatchmentAreaInformation.vue';
    import MatchingLocations from '@/components/main/MatchingLocations.vue';
    import EditLocation from '@/components/main/EditLocation.vue';
    import AreaInformation from '@/components/main/AreaInformation.vue';
    import EditScenario from '@/components/main/EditScenario.vue';
    import CloseLocation from '@/components/main/CloseLocation.vue';
    import EditScenarioName from '@/components/main/EditScenarioName.vue';
    import CopyChanges from '@/components/main/CopyChanges.vue';
    import AreaScores from '@/components/main/AreaScores.vue';
    import EditNormalizedScoresFunctions from '@/components/main/EditNormalizedScoresFunctions.vue';
    import MatchingLocationsOutputChart from '@/components/main/MatchingLocationsOutputChart.vue';
    import MatchingLocationsRadarChart from '@/components/main/MatchingLocationsRadarChart.vue';
    import DriversChart from '@/components/main/DriversChart.vue';
    import PropertiesChart from '@/components/main/PropertiesChart.vue';
    import LocationReport from '@/components/main/LocationReport.vue';
    import ScenarioReport from '@/components/main/ScenarioReport.vue';
    import Log from '@/components/main/Log.vue';
    const namespace = 'main';

    @Component({
        components: {
           AreaScores,
            FloatingMenu,
            EditModelParameters,
            EditDrivers,
            EditLocation,
            CloseLocation,
            EditScenario,
            CopyChanges,
            EditNormalizedScoresFunctions,
            GoogleMap,
            Map,
            LocationInformation,
            ModelOutput,
            CatchmentAreaInformation,
            ScenarioProcessingLog,
            LocationScores,
            Dialog,
            AreaInformation,
            MatchingLocations,
            MatchingLocationsOutputChart,
            MatchingLocationsRadarChart,
            DriversChart,
            PropertiesChart,
            LocationReport,
            ScenarioReport,
            EditScenarioName,
            Log
        },
    })
    export default class Home extends Vue {

        @Getter('getDrivers', { namespace }) getDrivers: any;
        @Getter('getModelParameters', { namespace }) getModelParameters: any;
        @Getter('getNormalizedScoresFunctions', { namespace }) getNormalizedScoresFunctions: any;
        @Action('calculateScenario', { namespace }) calculateScenario: any;
        
        private rightClick(args: any) {
            this.mapContextMenuArguments = args;
            this.locationContextMenuArguments = null;
        }

        private leftClickLocation(args: any) {
            
            this.setLocation(args.location);
        }

        private async leftClickScenarioLocation(args: any) {
            
            await this.setScenarioById(args.scenarioLocation.scenarioId);
            await this.setLocationById(args.scenarioLocation.locationId);
        }

        private rightClickLocation(args: any) {
            this.mapContextMenuArguments = null;
            this.locationContextMenuArguments = args;
        }


        private navigateTo(url: any) {
            window.open(url, "_blank");
        }

        private locationContextMenuArguments: any = null;
        private mapContextMenuArguments: any = null;

        private leftDrawer = {
            width: window.innerWidth > 1200 ? 400 : window.innerWidth / 3
        };
        private rightDrawer = {
            width: window.innerWidth > 1200 ? 400 : window.innerWidth / 3
        };
        
        private convertToDMS(degree) {
          const absolute = Math.abs(degree);
          const degrees = Math.floor(absolute);
          const minutesNotTruncated = (absolute - degrees) * 60;
          const minutes = Math.floor(minutesNotTruncated);
          const seconds = ((minutesNotTruncated - minutes) * 60).toFixed(2);
  
          return { degrees, minutes, seconds };
        }
        private formatCoordinates(latitude, longitude) {
            const latDMS = this.convertToDMS(latitude);
            const lonDMS = this.convertToDMS(longitude);
  
            // Determine the direction (N/S/E/W) based on the sign of the coordinates
            const latDirection = latitude >= 0 ? 'N' : 'S';
            const lonDirection = longitude >= 0 ? 'E' : 'W';
  
            // Format the coordinates with degrees, minutes, and seconds
            const formattedLat = `${latDMS.degrees}° ${latDMS.minutes}′ ${latDMS.seconds}″ ${latDirection}`;
            const formattedLon = `${lonDMS.degrees}° ${lonDMS.minutes}′ ${lonDMS.seconds}″ ${lonDirection}`;
  
            // Return the formatted coordinates
            return `${formattedLat}, ${formattedLon}`;
        }


        private locationReportVisible: any = false;
        private scenarioReportVisible: any = false;
        private matchingLocationsOutputChartVisible: any = false;
        private matchingLocationsRadarChartVisible: any = false;
        private driversChartVisible: any = false;
        private propertiesChartVisible: any = false;
        private locationScoresVisible: any = false;
        private areaScoresVisible: any = false;
        private logVisible: any = false;

        

        @Getter('getNotificationText') getNotificationText!: string;
        @Mutation('SET_NOTIFICATIONTEXT') setNotificationText: any;
        @Getter('getFilterLocations', { namespace }) getFilterLocations: any;
        @Getter('getSearchResults', { namespace }) getSearchResults: any;
        @Getter('getCatchmentArea', { namespace }) getCatchmentArea: any;
        @Getter('getReadOnly', { namespace }) getReadOnly: any;
        @Getter('getPois', { namespace }) getPois: any;
        @Getter('getHeatMapPoints', { namespace }) getHeatMapPoints: any;
        @Getter('getEnvironment') getEnvironment!: any;
        @Getter('getModels', { namespace }) getModels: any;
        @Getter('getArea', { namespace }) getArea: any;
        @Getter('getModel', { namespace }) getModel: any;
        @Getter('getSubModel', { namespace }) getSubModel: any;
        @Action('setModel', { namespace }) setModel: any;
        @Getter('getScenarios', { namespace }) getScenarios: any;
        @Getter('getScenarioLocationsVisible', { namespace }) getScenarioLocationsVisible: any;
        @Getter('getScenarioLocations', { namespace }) getScenarioLocations: any;
        @Getter('getScenario', { namespace }) getScenario: any;
        @Getter('getAreaSetsVisible', { namespace }) getAreaSetsVisible: any;
        @Getter('getLocation', { namespace }) getLocation: any;
        @Getter('getLocationPhoto', { namespace }) getLocationPhoto: any;
        @Getter('getLocations', { namespace }) getLocations: any;
        @Action('setScenario', { namespace }) setScenario: any;
        @Action('setScenarioById', { namespace }) setScenarioById: any;
        @Action('setLocationById', { namespace }) setLocationById: any;
        @Action('setLocationByIdWithFocus', { namespace }) setLocationByIdWithFocus: any;
        @Getter('getScenarioDifferences', { namespace }) getScenarioDifferences: any;
        @Getter('getMatchingLocations', { namespace }) getMatchingLocations: any;
        @Getter('getMatchingScores', { namespace }) getMatchingScores: any;
        @Getter('getLocationDrivers', { namespace }) getLocationDrivers: any;
        @Getter('getPropertiesChart', { namespace }) getPropertiesChart: any;
        @Getter('getMatchingColors', { namespace }) getMatchingColors: any;
        @Getter('getLog', { namespace }) getLog: any;
        @Action('setLocation', { namespace }) setLocation: any;
        @Getter('getLocationProperties', { namespace }) getLocationProperties: any;
        @Getter('getLocationPropertiesOld', { namespace }) getLocationPropertiesOld: any;
        
        @Getter('getLocationScores', { namespace }) getLocationScores: any;
        @Getter('getLocationGroupProperties', { namespace }) getLocationGroupProperties: any;
        @Getter('getLocationCluster', { namespace }) getLocationCluster: any;
        @Getter('getLocationIsoLines', { namespace }) getLocationIsoLines: any;
        @Getter('getLocationCatchmentArea', { namespace }) getLocationCatchmentArea: any;
        @Getter('getProcessingLog', { namespace }) getProcessingLog: any;
        @Getter('getScenarioProcessingLog', { namespace }) getScenarioProcessingLog: any;
        @Getter('getIsoLineSet', { namespace }) getIsoLineSet: any;
        @Getter('getIsoLineMinutes', { namespace }) getIsoLineMinutes: any;
        @Mutation('SET_LOCATIONDRIVERS', { namespace }) setLocationDrivers: any;
        @Mutation('SET_LOG', { namespace }) setLog: any;
        @Mutation('SET_MAPBOUNDS', { namespace }) setMapBounds: any;
        @Action('fetchAreaWithLocations', { namespace }) fetchAreaWithLocations: any;
        @Action('showPropertiesChart', { namespace }) showPropertiesChart: any;
        @Action('fetchPropertiesChartData', { namespace }) fetchPropertiesChartData: any;
        @Action('fetchArea', { namespace }) fetchArea: any;
        @Action('fetchLocationScores', { namespace }) fetchLocationScores: any;
        @Action('fetchDriversForLocation', { namespace }) fetchDriversForLocation: any;
        @Action('fetchMatchingScores', { namespace }) fetchMatchingScores: any;
        @Action('fetchScenarioParents', { namespace }) fetchScenarioParents: any;
        @Action('clearModelArea', { namespace }) clearModelArea: any;
        @Action('clearArea', { namespace }) clearArea: any;
        @Getter('getLocationTravelTimeMode', { namespace }) getLocationTravelTimeMode: any;
        @Getter('getLocationTravelTimeMinutes', { namespace }) getLocationTravelTimeMinutes: any;
        @Action('saveModelParameters', { namespace }) saveModelParameters: any;
        @Action('saveNormalizedScoresFunctions', { namespace }) saveNormalizedScoresFunctions: any;
        @Action('saveDrivers', { namespace }) saveDrivers: any;
        @Action('editNewLocation', { namespace }) editNewLocation: any;
        @Getter('getLocationEditing', { namespace }) getLocationEditing: any;
        @Getter('getLocationClosing', { namespace }) getLocationClosing: any;
        @Action('saveLocationEditing', { namespace }) saveLocationEditing: any;
        @Action('saveLocationClosing', { namespace }) saveLocationClosing: any;
        
        @Getter('getScenarioEditing', { namespace }) getScenarioEditing: any;
        @Getter('getScenarioNameEditing', { namespace }) getScenarioNameEditing: any;
        @Getter('getScenarioMerging', { namespace }) getScenarioMerging: any;
		@Getter('getOnlinePois', { namespace }) getOnlinePois: any;
        @Action('saveScenarioEditing', { namespace }) saveScenarioEditing: any;
        @Action('saveScenarioNameEditing', { namespace }) saveScenarioNameEditing: any;
        @Action('closeLocation', { namespace }) closeLocation: any;
        @Action('undoCloseLocation', { namespace }) undoCloseLocation: any;
        @Action('undoEditLocation', { namespace }) undoEditLocation: any;
        @Action('removeLocation', { namespace }) removeLocation: any;
        @Action('fetchScenarioDifferences', { namespace }) fetchScenarioDifferences: any;
        @Action('clearScenarioDifferences', { namespace }) clearScenarioDifferences: any;
        @Action('setLocationPropertiesVisibility', { namespace }) setLocationPropertiesVisibility: any;
        @Mutation('SET_STARTBUSY') setStartBusy: any;
        @Mutation('SET_ENDBUSY') setEndBusy: any;
        @Mutation('SET_STARTINPROGRESS') setStartInProgress: any;
        @Mutation('SET_ENDINPROGRESS') setEndInProgress: any;
        
        @Getter('getActiveCatchmentAreaId', { namespace }) getActiveCatchmentAreaId: any;
        @Action('editExistingLocation', { namespace }) editExistingLocation: any;
        @Action('fetchModels', { namespace }) fetchModels: any;
        @Getter('getIsLoggedIn') getIsLoggedIn: any;
        @Action('fetchLog', { namespace }) fetchLog: any;
        @Action('saveMergingScenario',{ namespace}) saveMergingScenario: any;

        @Watch('getIsLoggedIn')
        private onGetIsLoggedInChanged() {
            this.fetchModels();
        }

        private async editNewLocationWithDefaultCheck(payload: any) {
            this.editNewLocation(payload);
        }

        private async editLocation(payload: any) {
            if (this.getLocation == null || payload.id != this.getLocation.id) await this.setLocation(payload);
            this.editExistingLocation();
        }

        private async confirmRemoveLocation(location : any) {
            const res = await this.$confirm(String(this.$t('message.confirmRemoveLocation')), { buttonTrueText: String(this.$t('message.yes')), buttonFalseText: String(this.$t('message.no')) });
            if (res) this.removeLocation(location);
        }

        private async confirmUndoEditLocation(location: any) {
            const res = await this.$confirm(String(this.$t('message.confirmUndoEditLocation')), { buttonTrueText: String(this.$t('message.yes')), buttonFalseText: String(this.$t('message.no')) });
            if (res) this.undoEditLocation(location);
        }

        
        get models(): any {
            return this.getModels;
        }

        get model(): any {
            return this.getModel;
        }

        set model(value: any) {
            this.setModel(value);
        }

        get scenarios(): any {
            return this.getScenarios;
        }

        get scenarioLocations(): any {
            return this.getScenarioLocations;
        }

        get scenario(): any {
            return this.getScenario;
        }

        set scenario(value: any) {
            this.setScenario(value);
        }

        get locations(): any {
            return this.getLocations;
        }

        get location(): any {
            return this.getLocation;
        }

        set location(value: any) {
            this.setLocation(value);
        }

        get changedLocations() {
            return this.locations.filter(function (location: any) {
                return location.changeType != 0;
            });
        }

        get visibleAreaSets() {
            const _self = this;
            return _self.getModel.areaSets.filter(function (areaSet: any) {
                return _self.getAreaSetsVisible.includes(areaSet.id);
            });
        }

        private editLocationChanged(properties: any) {
            this.setLocationPropertiesVisibility(properties);
        }

        private async showDriversChart() {
            await this.fetchDriversForLocation();
            this.driversChartVisible = true;
        }

         private async saveMergingScenarioLocal() {

             
            await this.saveMergingScenario();
            
        }


        private async showMatchingScores() {
            await this.fetchMatchingScores();
            this.matchingLocationsRadarChartVisible = true;
        }

        private async showLocationReport() {
            await this.fetchDriversForLocation();
            await this.fetchMatchingScores();
            this.locationReportVisible = false;
            this.locationReportVisible = true
        }

        private async showLocationScores() {
            await this.fetchLocationScores();
            this.locationScoresVisible = false;
            this.locationScoresVisible = true
        }

        private mounted() {
            const leftDrawerInterval = setInterval(() => {
                if (this.$refs.leftDrawer == null) return;
                const ld = (this.$refs.leftDrawer as HTMLElement);
               
                if (ld != null) {
                    this.setEvents(ld, this.leftDrawer);
                    clearInterval(leftDrawerInterval)
                }
            }, 50)
            const rightDrawerInterval = setInterval(() => {
                if (this.$refs.rightDrawer == null) return;
                const ld = (this.$refs.rightDrawer as HTMLElement);
                if (ld != null) {
                    this.setEvents(ld, this.rightDrawer);
                    clearInterval(rightDrawerInterval)
                }
            }, 50)
            if (this.getIsLoggedIn) this.fetchModels();
        }

        private legendData: any = null;

        private legendChanged(legendData: any) {
            this.legendData = legendData;
        }

        private setEvents(el: any, struct: any) {

            



            const drawerBorder = el.querySelector(".tbdc-drawerborder");
            const direction = el.classList.contains("tbdc-drawerright") ? "right" : "left";
            function resize(e: any) {
                document.body.style.cursor = "ew-resize";
                const f =
                    direction === "right"
                        ? document.body.scrollWidth - e.clientX
                        : e.clientX;
                el.style.width = f + "px";
            }
            drawerBorder.addEventListener(
                "mousedown",
                (e: any) => {
                    if (e.offsetX < drawerBorder.getBoundingClientRect().width) {
                        el.style.transition = "initial";
                        document.addEventListener("mousemove", resize, false);
                    }
                },
                false
            );

            document.addEventListener(
                "mouseup",
                () => {
                    el.style.transition = "";
                    struct.width = el.style.width;
                    document.body.style.cursor = "";
                    document.removeEventListener("mousemove", resize, false);

                },
                false
            );
        }
    }
